import React from 'react';

const SelectInput = ({ name, label, options, value, onChange }) => {
  console.log(value);
  return (
    <div className="mb-3">
      <label className="form-label1" htmlFor={name}>{label}</label>
      <select
        className="form-select form-select-sm"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        <option value="">Select {label.toLowerCase()}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;