import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../../../components/css/Sidebar.css'
import SelectInput from "../../partials/SelectInput";

const HtRoutePoint = ({ setRightSidebarVisible, selectedMarker, onSave,onFormSave, networkPayload}) => {
  const [formData, setFormData] = useState({
    has_physical_marker: (selectedMarker?.options?.eleProp?.properties?.point_props.has_physical_marker || ''),
    has_joint: (selectedMarker?.options?.eleProp?.properties?.point_props.has_joint || ''),
    position: (selectedMarker?.options?.eleProp?.properties?.line_props.position || ''),
    type: (selectedMarker?.options?.eleProp?.properties?.line_props.support_type || '')
  });

  const [showLoadAndStartButtons, setShowLoadAndStartButtons] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAsCopyDisabled, setIsSaveAsCopyDisabled] = useState(true);

  // Load data from localStorage and check button visibility
  useEffect(() => {
    const savedData = localStorage.getItem('htRoutePointData');
    setShowLoadAndStartButtons(!!savedData);
    if (selectedMarker?.options?.eleProp?.id) {
        const data = networkPayload.find(
            (item) => item.id === selectedMarker?.options?.eleProp?.id
        );
        setFormData({
          has_physical_marker: (data?.properties?.point_props.has_physical_marker || ''),
          has_joint: (data?.properties?.point_props.has_joint || ''),
          position: data?.properties?.line_props.position || "",
          type: data?.properties?.line_props.type || "",
        });
    }
}, [selectedMarker]);

  // Update save buttons state based on form data
  useEffect(() => {
    const allFieldsFilled = Object.entries(formData).every(([key, value]) => {
      // Allow 'remarks' to be blank
      if (key === 'remarks') return true;
      return value !== '';
    });
    setIsSaveDisabled(!allFieldsFilled);
    setIsSaveAsCopyDisabled(!allFieldsFilled);
  }, [formData]);

  // Clear form data
  const clearForm = () => {
    setFormData({
      has_physical_marker: '',
      has_joint: '',
      position: '',
      type: ''
    });
    const savedData = localStorage.getItem('htRoutePointData');
    setShowLoadAndStartButtons(!!savedData);
  };

  // Handle form changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const element = selectedMarker.options.eleProp;
    // Prepare the form data
    const requestBody = {
      "id": element.id,
      "type": "Feature",
      "geometry": element.geometry,
      "project_id": element.project_id,
      "properties": {
        "id": element.id,
        "parent_id": element.properties.parent_id,
        "point_type": "HT Route Point",
        "point_props": {
          structure_type: formData.structure_type,
          has_physical_marker: formData.has_physical_marker,
          has_joint: formData.has_joint,
        },
        "line_props": {
          position: formData.position,
          type: formData.type
        },
        "data_type": "ht_location"
      }
    };
    onFormSave();
    onSave(requestBody);
    toast.success("Data Save Successfully!")
    setRightSidebarVisible(false)
    clearForm();
  };
  // Handle save as copy
  const handleSaveAsCopy = (e) => {
    if (!isSaveAsCopyDisabled) {
      localStorage.setItem('htRoutePointData', JSON.stringify(formData));
       // Clear the form after saving as copy
       handleSubmit(e)
    }
  };

  // Handle load last copy
  const handleLoadLastCopy = () => {
    const savedData = localStorage.getItem('htRoutePointData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  };

  // Handle Clear
  const handleStartNew = () => {
    clearForm();
  };

  return (
    <div>
      {/* Show Load Last Copy and Clear buttons if there is saved data */}
      {showLoadAndStartButtons && (
        <div className="mb-3">
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
              marginRight: '10px'
            }}
            onClick={handleLoadLastCopy}
          >
            Load Last Copy
          </button>
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
            }}
            onClick={handleStartNew}
          >
            Clear
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_physical_marker">Physical Marker Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_physical_marker"
                value={formData.has_physical_marker}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_joint">Joint Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_joint"
                value={formData.has_joint}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <h6 className="sub-menu-title mb-0">
                Line Properties
              </h6>
            </div>
          </div>
          <div className="col-md-12">
              <SelectInput 
                  name="position"
                  label="Line Position *"
                  options={["Overhead", "Underground"]}
                  value={formData.position}
                  onChange={handleChange}
              />
          </div>
          <div className="col-md-12">
              <SelectInput 
                  name="type"
                  label="Line Type *"
                  options={formData.position === "Underground" ? ["3C X 70sqmm","3C X 95sqmm","3C x 120sqmm","3C X 150sqmm","3C X 185sqmm","3C X 225sqmm","3C X 240sqmm","3C X 300 sqmm"] : ["AAAC-Weasel (34 mm2)", "AAAC-Rabbit (55 mm2)", "AAAC-DOG (100 mm2)", "AAAC Rabbit - Coated (55mm2)", "AB Cable (120 mm2)", "AB Cable (35 mm2)", "AB Cable (70 mm2)", "AB Cable (95 mm2)", "AB Cable(120 mm2)", "ACSR-DOG (105 mm2)", "ACSR-Rabbit (50 mm2)", "ACSR-Rabbit (55 mm2)", "ACSR-Wease (30 mm2)", "ACSR-Weasel (34 mm2)", "AL 59 Dog (117 mm2)", "MVCC AL59 ACS Rabbit(52 mm2)", "3C X 70sqmm", "3C X 95sqmm", "3C x 120sqmm", "3C X 150sqmm", "3C X 185sqmm", "3C X 225sqmm", "3C X 240sqmm", "3C X 300 sqmm"]}
                  value={formData.type}
                  onChange={handleChange}
              />
          </div>
        </div>
        <div className="text-center">
          <button
            type="button"
            className="save-button"
            onClick={handleSaveAsCopy}
            disabled={isSaveAsCopyDisabled}
          >
            Save as Copy
          </button>
          <button
            type="submit"
            className="save-button"
            onClick={handleSubmit}
            disabled={isSaveDisabled}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default HtRoutePoint;
