import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import "./../css/task.css";

const ViewTaskNetwork = ({ tableData, fetchTasks, showModal, setShowModal, selectedTaskId }) => {
  const [status, setStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [statusError, setStatusError] = useState('');
  const [remarksError, setRemarksError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    if (e.target.value) setStatusError('');  // Clear error when value is selected
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
    if (e.target.value) setRemarksError('');  // Clear error when remarks are typed
  };

  const validateForm = () => {
    let isValid = true;

    // Validate Status
    if (!status) {
      setStatusError('Please select a status.');
      isValid = false;
    } else {
      setStatusError('');
    }

    // Validate Remarks
    if (!remarks) {
      setRemarksError('Please enter remarks.');
      isValid = false;
    } else {
      setRemarksError('');
    }

    return isValid;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const formdata = new FormData();
            formdata.append(
                "status",
                status
            );
            formdata.append(
              "remarks",
              remarks
          );
            const requestOptions = {
                method: "POST",
                body: formdata,
                redirect: "follow",
            };
            fetch(
                process.env.REACT_APP_API_URL + `/api/task/${selectedTaskId}/update-status`,
                requestOptions
            )
                .then((response) => response.json())
                .then((result) => {
                  setSuccessMessage('Status updated successfully!');
                  setShowModal(false);
                  fetchTasks();                    
                })
                .catch((error) => {
                });

      setStatus('')
      setRemarks('')
    } catch (error) {
    //   setRemarksError('Failed to update status. Please try again.');
    setShowModal(false);
    setStatus('')
    setRemarks('')
      console.error('Error updating status:', error);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div style={{ margin: '70px 20px 0 90px' }}>
      <MDBDataTable striped bordered hover data={tableData} />
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Network Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form.Group controlId="statusSelect">
              <Form.Label>Status</Form.Label>
              <Form.Control as="select" value={status} onChange={handleStatusChange} isInvalid={!!statusError}>
                <option value="">Select Status</option>
                <option value="draft">Draft</option>
                <option value="submited">Submited</option>
                <option value="validated">Validated</option>
                <option value="approved">Approved</option>
                <option value="deleted">Deleted</option>
                <option value="rejected">Rejected</option>
              </Form.Control>
              {statusError && <div className="text-danger">{statusError}</div>} {/* Show status error */}
            </Form.Group>

            <Form.Group controlId="remarksTextarea">
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={remarks}
                onChange={handleRemarksChange}
                isInvalid={!!remarksError}
                placeholder="Enter remarks"
              />
              {remarksError && <div className="text-danger">{remarksError}</div>} {/* Show remarks error */}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ViewTaskNetwork;
