import React from 'react';

const Transformer = ({ formData, onFormDataChange }) => {

    const handleChange = (e) => {
        const { id, value } = e.target;
        onFormDataChange(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    return (
        <div>
            <form>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="support_type">Support Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="support_type"
                                value={formData.support_type}
                                onChange={handleChange}
                            >
                                <option value="">Select support type</option>
                                <option value="PSC">PSC</option>
                                <option value="MS GIRDER">MS GIRDER</option>
                                <option value="TABULAR">TABULAR</option>
                                <option value="FRP">FRP</option>
                                <option value="RAIL">RAIL</option>
                                <option value="PLINTH">PLINTH</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="structure_type"
                                value={formData.structure_type}
                                onChange={handleChange}
                            >
                                <option value="">Select structure type</option>
                                <option value="Double Pole">Double Pole</option>
                                <option value="Single Pole">Single Pole</option>
                                <option value="Double Pole+EPD">Double Pole+EPD</option>
                                <option value="Plinth">Plinth</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="la_type">LA Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="la_type"
                                value={formData.la_type}
                                onChange={handleChange}
                            >
                                <option value="">Select LA type</option>
                                <option value="Fix">Fix</option>
                                <option value="Fuse">Fuse</option>
                                <option value="Not Installed">Not Installed</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="ht_fuse_type">HT Fuse Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="ht_fuse_type"
                                value={formData.ht_fuse_type}
                                onChange={handleChange}
                            >
                                <option value="">Select HT fuse type</option>
                                <option value="Drop Out Fuse">Drop Out Fuse</option>
                                <option value="Rewirable Fuse">Rewirable Fuse</option>
                                <option value="HRC Fuse">HRC Fuse</option>
                                <option value="Not Installed">Not Installed</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="lt_fuse_type">LT Fuse Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="lt_fuse_type"
                                value={formData.lt_fuse_type}
                                onChange={handleChange}
                            >
                                <option value="">Select LT fuse type</option>
                                <option value="Rewirable Fuse">Rewirable Fuse</option>
                                <option value="HRC Fuse">HRC Fuse</option>
                                <option value="Circuit Breaker">Circuit Breaker</option>
                                <option value="Drop Out Fuse">Drop Out Fuse</option>
                                <option value="Not Installed">Not Installed</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="earthing_type"
                                value={formData.earthing_type}
                                onChange={handleChange}
                            >
                                <option value="">Select earthing type</option>
                                <option value="GI Wire">GI Wire</option>
                                <option value="GI Strip">GI Strip</option>
                                <option value="Not Provided">Not Provided</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="xmr_type">Transformer Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="xmr_type"
                                value={formData.xmr_type}
                                onChange={handleChange}
                            >
                                <option value="">Select transformer type</option>
                                <option value="Dist Transformer(Oil)">Dist Transformer(Oil)</option>
                                <option value="Dist Trans (Oil+LV Encl)">Dist Trans (Oil+LV Encl)</option>
                                <option value="Dist Transformer(Dry)">Dist Transformer(Dry)</option>
                                <option value="WDT">WDT</option>
                                <option value="SDT">SDT</option>
                                <option value="PLMT">PLMT</option>
                                <option value="Power Transformer">Power Transformer</option>
                                <option value="Station Transformer">Station Transformer</option>
                                <option value="Buster Transformer">Buster Transformer</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="xmr_core_type">Core Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="xmr_core_type"
                                value={formData.xmr_core_type}
                                onChange={handleChange}
                            >
                                <option value="">Select core type</option>
                                <option value="CRGO">CRGO</option>
                                <option value="Amorphous">Amorphous</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="capacity">Capacity *</label>
                            <select
                                className="form-select form-select-sm"
                                id="capacity"
                                value={formData.capacity}
                                onChange={handleChange}
                            >
                                <option value="">Select capacity</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="16">16</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="63">63</option>
                                <option value="75">75</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                                <option value="250">250</option>
                                <option value="315">315</option>
                                <option value="350">350</option>
                                <option value="500">500</option>
                                <option value="750">750</option>
                                <option value="800">800</option>
                                <option value="900">900</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="has_fencing">Fencing Available *</label>
                            <select
                                className="form-select form-select-sm"
                                id="has_fencing"
                                value={formData.has_fencing}
                                onChange={handleChange}
                            >
                                <option value="">Select option</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="scheme">Scheme</label>
                            <select
                                className="form-select form-select-sm"
                                id="scheme"
                                value={formData.scheme}
                                onChange={handleChange}
                            >
                                <option value="">Select scheme</option>
                                <option value="ND">ND</option>
                                <option value="DISS">DISS</option>
                                <option value="DDUJGY">DDUJGY</option>
                                <option value="IPDS">IPDS</option>
                                <option value="RE">RE</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="remarks">Remarks</label>
                            <textarea
                                className="form-control"
                                id="remarks"
                                rows="3"
                                value={formData.remarks}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Transformer;
