import React, { useState, useEffect } from "react";

const FileUpload = ({ fileName, existingFile, onFileUpload, onFileRemove }) => {
    const [file, setFile] = useState(null); // Start with the existing file if provided
    const [isUploading, setIsUploading] = useState(false);
    const [isExistingFile, setIsExistingFile] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!file){
            const fileBool = !!(existingFile && typeof existingFile === "string" && existingFile.trim() !== "")
            setIsExistingFile(fileBool);
        }
    }, [existingFile]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setError(null);
            handleFileUpload(selectedFile); // Trigger the upload after selection
        }
    };

    const handleRemoveFile = () => {
        setFile(null); // Clear the file state
        setError(null);
        setIsExistingFile(false);
        setIsUploading(false); // Allow file input again
        onFileRemove(fileName);
    };

    const handleFileUpload = async (fileToUpload) => {
        setIsUploading(true);

        const formData = new FormData();
        formData.append("file", fileToUpload);

        try {
            // Simulate API call to upload the file
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/api/file-upload`,
                {
                    method: "POST",
                    body: formData,
                }
            );

            const result = await response.json();
            if (result.success) {
                const fileUrl = result.data.fileUrl;
                onFileUpload(fileName, fileUrl); // Pass file URL to parent
                setFile(fileUrl); // Update the file state to the uploaded file URL
                setError(null);
            } else {
                setError("File upload failed.");
            }
        } catch (error) {
            setError("Error during file upload.");
            console.error(error);
        } finally {
            setIsUploading(false); // Finish uploading process
        }
    };

    return (
        <div>
            {file || isExistingFile ? (
                <div className="text-start">
                    <button
                        type="button"
                        className="btn-custom btn-sm"
                        onClick={() => window.open(isExistingFile ? existingFile : file)}
                    >
                        View File
                    </button>
                    <button
                        type="button"
                        className="btn-bordered btn-sm"
                        onClick={handleRemoveFile}
                    >
                        Remove File
                    </button>
                </div>
            ) : (
                <input
                    type="file"
                    onChange={handleFileChange}
                    disabled={isUploading}
                    className="form-control form-control-sm"
                />
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
    );
};

export default FileUpload;
