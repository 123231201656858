import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../../../components/css/Sidebar.css'
import SelectInput from "../../partials/SelectInput";

const GentryAddNetwork = ({ setRightSidebarVisible, selectedMarker, onSave,onFormSave,networkPayload }) => {
  const [formData, setFormData] = useState({
    support_type: (selectedMarker?.options?.eleProp?.properties?.point_props.support_type || ''),
    structure_type: (selectedMarker?.options?.eleProp?.properties?.point_props.structure_type || ''),
    insulator_type: (selectedMarker?.options?.eleProp?.properties?.point_props.insulator_type || ''),
    earthing_type: (selectedMarker?.options?.eleProp?.properties?.point_props.earthing_type || ''),
    la_type: (selectedMarker?.options?.eleProp?.properties?.point_props.la_type || ''),
    switch_type: (selectedMarker?.options?.eleProp?.properties?.point_props.switch_type || ''),
    position: (selectedMarker?.options?.eleProp?.properties?.line_props.position || ''),
    type: (selectedMarker?.options?.eleProp?.properties?.line_props.type || '')
  });

  const [showLoadAndStartButtons, setShowLoadAndStartButtons] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAsCopyDisabled, setIsSaveAsCopyDisabled] = useState(true);

  // Load data from localStorage and check button visibility
  useEffect(() => {
    const savedData = localStorage.getItem('gentryAddNetworkData');
    setShowLoadAndStartButtons(!!savedData);
    if (selectedMarker?.options?.eleProp?.id) {
        const data = networkPayload.find(
            (item) => item.id === selectedMarker?.options?.eleProp?.id
        );
        setFormData({
            support_type: data?.properties?.point_props.support_type || "",
            structure_type: data?.properties?.point_props.structure_type || "",
            earthing_type: data?.properties?.point_props.earthing_type || "",
            insulator_type: (data?.properties?.point_props.insulator_type || ''),
            la_type: (data?.properties?.point_props.la_type || ''),
            switch_type: (data?.properties?.point_props.switch_type || ''),
            position: data?.properties?.line_props.position || "",
            type: data?.properties?.line_props.type || "",
        });
    }
}, [selectedMarker]);

  // Update save buttons state based on form data
  useEffect(() => {
    const allFieldsFilled = Object.entries(formData).every(([key, value]) => {
      // Allow 'remarks' to be blank
      if (key === 'remarks') return true;
      return value !== '';
    });
    setIsSaveDisabled(!allFieldsFilled);
    setIsSaveAsCopyDisabled(!allFieldsFilled);
  }, [formData]);

  // Clear form data
  const clearForm = () => {
    setFormData({
      support_type: '',
      structure_type: '',
      insulator_type: '',
      earthing_type: '',
      la_type: '',
      switch_type: '',
      position: '',
      type: ''
    });
    const savedData = localStorage.getItem('gentryAddNetworkData');
    setShowLoadAndStartButtons(!!savedData);
  };

  // Handle form changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const element = selectedMarker.options.eleProp;
    // Prepare the form data
    const requestBody = {
      "id": element.id,
      "type": "Feature",
      "geometry": element.geometry,
      "project_id": element.project_id,
      "properties": {
        "id": element.id,
        "parent_id": element.properties.parent_id,
        "point_type": "Gentry",
        "point_props": {
          support_type: formData.support_type,
          structure_type: formData.structure_type,
          insulator_type: formData.insulator_type,
          earthing_type: formData.earthing_type,
          la_type: formData.la_type,
          switch_type: formData.switch_type
        },
        "line_props": {
          position: formData.position,
          type: formData.type
        },
        "data_type": "ht_location"
      }
    };
    onFormSave();
    onSave(requestBody);
    toast.success("Data Save Successfully!");
    setRightSidebarVisible(false);
    clearForm();
  };

  // Handle save as copy
  const handleSaveAsCopy = (e) => {
    if (!isSaveAsCopyDisabled) {
      localStorage.setItem('gentryAddNetworkData', JSON.stringify(formData));
      handleSubmit(e);
    }
  };

  // Handle load last copy
  const handleLoadLastCopy = () => {
    const savedData = localStorage.getItem('gentryAddNetworkData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  };

  // Handle Clear
  const handleStartNew = () => {
    clearForm();
  };

  return (
    <div>
      {/* Show Load Last Copy and Clear buttons if there is saved data */}
      {showLoadAndStartButtons && (
        <div className="mb-3">
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
              marginRight: '10px'
            }}
            onClick={handleLoadLastCopy}
          >
            Load Last Copy
          </button>
          <button
            type="button"
            className="save-button"
            style={{
              padding: "8px 30px",
              backgroundColor: "#427d9d",
              borderRadius: "8px",
              color: "white",
              fontSize: "12px",
              marginTop: "20px",
            }}
            onClick={handleStartNew}
          >
            Clear
          </button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="support_type">Support Type *</label>
              <select
                className="form-select form-select-sm"
                id="support_type"
                value={formData.support_type}
                onChange={handleChange}
              >
                <option value="">Select support type</option>
                <option value="PSC">PSC</option>
                <option value="MS GIRDER">MS GIRDER</option>
                <option value="FRP">FRP</option>
                <option value="RAIL">RAIL</option>
                <option value="PLINTH">PLINTH</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
              <select
                className="form-select form-select-sm"
                id="structure_type"
                value={formData.structure_type}
                onChange={handleChange}
              >
                <option value="">Select structure type</option>
                <option value="Double Pole">Double Pole</option>
                <option value="Single Pole">Single Pole</option>
                <option value="Plinth">Plinth</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="insulator_type">Insulator Type *</label>
              <select
                className="form-select form-select-sm"
                id="insulator_type"
                value={formData.insulator_type}
                onChange={handleChange}
              >
                <option value="">Select insulator type</option>
                <option value="Ceramic">Ceramic</option>
                <option value="Polymeric">Polymeric</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
              <select
                className="form-select form-select-sm"
                id="earthing_type"
                value={formData.earthing_type}
                onChange={handleChange}
              >
                <option value="">Select earthing type</option>
                <option value="GI Wire">GI Wire</option>
                <option value="GI Strip">GI Strip</option>
                <option value="Not Provided">Not Provided</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="la_type">LA Type *</label>
              <select
                className="form-select form-select-sm"
                id="la_type"
                value={formData.la_type}
                onChange={handleChange}
              >
                <option value="">Select LA type</option>
                <option value="Fix">Fix</option>
                <option value="Fuse">Fuse</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="switch_type">Switch Type *</label>
              <select
                className="form-select form-select-sm"
                id="switch_type"
                value={formData.switch_type}
                onChange={handleChange}
              >
                <option value="">Select switch type</option>
                <option value="AB Switch">AB Switch</option>
                <option value="ABEB Switch">ABEB Switch</option>
                <option value="ABEBC Switch">ABEBC Switch</option>
                <option value="Changeover Switch">Changeover Switch</option>
                <option value="Not Installed">Not Installed</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <h6 className="sub-menu-title mb-0">
                Line Properties
              </h6>
            </div>
          </div>
          <div className="col-md-12">
              <SelectInput 
                  name="position"
                  label="Line Position *"
                  options={["Overhead", "Underground"]}
                  value={formData.position}
                  onChange={handleChange}
              />
          </div>
          <div className="col-md-12">
              <SelectInput 
                  name="type"
                  label="Line Type *"
                  options={formData.position === "Underground" ? ["3C X 70sqmm","3C X 95sqmm","3C x 120sqmm","3C X 150sqmm","3C X 185sqmm","3C X 225sqmm","3C X 240sqmm","3C X 300 sqmm"] : ["AAAC-Weasel (34 mm2)", "AAAC-Rabbit (55 mm2)", "AAAC-DOG (100 mm2)", "AAAC Rabbit - Coated (55mm2)", "AB Cable (120 mm2)", "AB Cable (35 mm2)", "AB Cable (70 mm2)", "AB Cable (95 mm2)", "AB Cable(120 mm2)", "ACSR-DOG (105 mm2)", "ACSR-Rabbit (50 mm2)", "ACSR-Rabbit (55 mm2)", "ACSR-Wease (30 mm2)", "ACSR-Weasel (34 mm2)", "AL 59 Dog (117 mm2)", "MVCC AL59 ACS Rabbit(52 mm2)", "3C X 70sqmm", "3C X 95sqmm", "3C x 120sqmm", "3C X 150sqmm", "3C X 185sqmm", "3C X 225sqmm", "3C X 240sqmm", "3C X 300 sqmm"]}
                  value={formData.type}
                  onChange={handleChange}
              />
          </div>
        </div>
        <div className="text-center">
          <button
            type="button"
            className="save-button"
            onClick={handleSaveAsCopy}
            disabled={isSaveAsCopyDisabled}
          >
            Save as Copy
          </button>
          <button
            type="submit"
            className="save-button"
            disabled={isSaveDisabled}
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default GentryAddNetwork;
