import React from 'react';

const HT_routePoint = ({ formData, onFormDataChange }) => {

  const handleChange = (e) => {
    const { id, value } = e.target;
    onFormDataChange({
      ...formData,
      [id]: value
    });
  };

  return (
    <div>
      <form >
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_physical_marker">Physical Marker Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_physical_marker"
                value={formData.has_physical_marker}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="has_joint">Joint Available *</label>
              <select
                className="form-select form-select-sm"
                id="has_joint"
                value={formData.has_joint}
                onChange={handleChange}
              >
                <option value="">Select option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="scheme">Scheme</label>
              <select
                className="form-select form-select-sm"
                id="scheme"
                value={formData.scheme}
                onChange={handleChange}
              >
                <option value="">Select scheme</option>
                <option value="ND">ND</option>
                <option value="DISS">DISS</option>
                <option value="DDUJGY">DDUJGY</option>
                <option value="IPDS">IPDS</option>
                <option value="KHUSHY">KHUSHY</option>
                <option value="SSVY">SSVY</option>
                <option value="ZP-SCSP">ZP-SCSP</option>
                <option value="RDSS">RDSS</option>
              </select>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label1" htmlFor="comm_date">Commissioning Date</label>
              <input
                placeholder="Enter commissioning date"
                type="date"
                id="comm_date"
                className="form-control form-control-sm"
                value={formData.comm_date}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default HT_routePoint;
