import React from 'react';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const navigate = useNavigate();

  const authToken = localStorage.getItem('_auth')
  const handleLogin = () => {
    if (authToken) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };
  const handleLogout = () => {
    localStorage.removeItem('_auth')
    localStorage.removeItem('user')
    navigate('/login')
  }
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f4f8',
    padding: '20px',
  };

  const boxStyle = {
    backgroundColor: '#ffffff',
    border: '1px solid #d1d5db',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    textAlign: 'center',
    maxWidth: '400px',
    width: '100%',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s',
  };
const buttonStyleLogout ={
  backgroundColor: '#007bff',
  color: '#ffffff',
  border: 'none',
  borderRadius: '4px',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  marginTop: '20px',
  transition: 'background-color 0.3s',
  marginLeft:'20px'
}
  const buttonHoverStyle = {
    backgroundColor: '#0056b3',
  };

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>Welcome to IntelliGIS</div>
        <button
          style={buttonStyle}
          onClick={handleLogin}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
        >
          {authToken ? 'Dashboard' : 'Login'}
        </button>
        {authToken ?
          <button
            style={buttonStyleLogout}
            onClick={handleLogout}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
          >
            Logout
          </button> : ""}
      </div>
    </div>
  );
};

export default Landing;
