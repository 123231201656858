import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import '../../../components/css/Sidebar.css';
import FileUpload from "../../partials/FileUpload";
import SelectInput from "../../partials/SelectInput";

const TransformerAddNetwork = ({ setRightSidebarVisible, selectedMarker, onSave, onFormSave, networkPayload }) => {

    const [formData, setFormData] = useState({
        support_type: (selectedMarker?.options?.eleProp?.properties?.point_props.support_type || ''),
        structure_type: (selectedMarker?.options?.eleProp?.properties?.point_props.structure_type || ''),
        la_type: (selectedMarker?.options?.eleProp?.properties?.point_props.la_type || ''),
        ht_fuse_type: (selectedMarker?.options?.eleProp?.properties?.point_props.ht_fuse_type || ''),
        lt_fuse_type: (selectedMarker?.options?.eleProp?.properties?.point_props.lt_fuse_type || ''),
        earthing_type: (selectedMarker?.options?.eleProp?.properties?.point_props.earthing_type || ''),
        xmr_type: (selectedMarker?.options?.eleProp?.properties?.point_props.xmr_type || ''),
        xmr_core_type: (selectedMarker?.options?.eleProp?.properties?.point_props.xmr_core_type || ''),
        capacity: (selectedMarker?.options?.eleProp?.properties?.point_props.capacity || ''),
        has_fencing: (selectedMarker?.options?.eleProp?.properties?.point_props.has_fencing || ''),
        remarks: (selectedMarker?.options?.eleProp?.properties?.point_props.remarks || ''),
        position: (selectedMarker?.options?.eleProp?.properties?.line_props.position || ''),
        type: (selectedMarker?.options?.eleProp?.properties?.line_props.type || ''),
        fileUrl: (selectedMarker?.options?.eleProp?.properties?.point_props.fileUrl || ""),
        imgUrl: (selectedMarker?.options?.eleProp?.properties?.point_props.imgUrl || ""),
    });

    const [showLoadAndStartButtons, setShowLoadAndStartButtons] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [isSaveAsCopyDisabled, setIsSaveAsCopyDisabled] = useState(true);

    // Load data from localStorage and check button visibility
    useEffect(() => {
        const savedData = localStorage.getItem('transformerData');
        setShowLoadAndStartButtons(!!savedData);
        if (selectedMarker?.options?.eleProp?.id) {
            const data = networkPayload.find(
                (item) => item.id === selectedMarker?.options?.eleProp?.id
            );
            setFormData({
                support_type: (data?.properties?.point_props.support_type || ""),
                structure_type: (data?.properties?.point_props.structure_type || ""),
                earthing_type: (data?.properties?.point_props.earthing_type || ""),
                la_type: (data?.properties?.point_props.la_type || ''),
                lt_fuse_type: (data?.properties?.point_props.lt_fuse_type || ''),
                ht_fuse_type: data?.properties?.point_props.ht_fuse_type || "",
                xmr_type: (data?.properties?.point_props.xmr_type || ''),
                xmr_core_type: (data?.properties?.point_props.xmr_core_type || ''),
                capacity: (data?.properties?.point_props.capacity || ''),
                has_fencing: (data?.properties?.point_props.has_fencing || ""),
                remarks: (data?.properties?.point_props.remarks || ''),
                position: (data?.properties?.line_props.position || ""),
                type: (data?.properties?.line_props.type || ""),
                fileUrl: (data?.properties?.point_props.fileUrl || ""),
                imgUrl:  (data?.properties?.point_props.imgUrl || ""),
            });
        }
    }, [selectedMarker]);

    const exemptKeys = ['remarks', 'fileUrl', 'imgUrl'];

    // Update save buttons state based on form data
    useEffect(() => {
        const allFieldsFilled = Object.entries(formData).every(([key, value]) => {
            // Allow exempted keys to be blank
            if (exemptKeys.includes(key)) return true;
            return value !== '';
        });
        setIsSaveDisabled(!allFieldsFilled);
        setIsSaveAsCopyDisabled(!allFieldsFilled);
    }, [formData]);

    // Clear form data
    const clearForm = () => {
        setFormData({
            support_type: '',
            structure_type: '',
            la_type: '',
            ht_fuse_type: '',
            lt_fuse_type: '',
            earthing_type: '',
            xmr_type: '',
            xmr_core_type: '',
            capacity: '',
            has_fencing: '',
            scheme: '',
            remarks: '',
            position: '',
            type: '',
            fileUrl: "",
            imgUrl:"",
        });
        const savedData = localStorage.getItem('transformerData');
        setShowLoadAndStartButtons(!!savedData);
    };

    // Handle form changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleFileUpload = (key, fileUrl) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: fileUrl
        }));
    };

    const handleFileRemove = (key) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const element = selectedMarker.options.eleProp;
        // Prepare the form data
        const requestBody = {
            "id": element.id,
            "type": "Feature",
            "geometry": element.geometry,
            "project_id": element.project_id,
            "properties": {
                "id": element.id,
                "parent_id": element.properties.parent_id,
                "point_type": "Transformer",
                "point_props": {
                    support_type: formData.support_type,
                    structure_type: formData.structure_type,
                    la_type: formData.la_type,
                    ht_fuse_type: formData.ht_fuse_type,
                    lt_fuse_type: formData.lt_fuse_type,
                    earthing_type: formData.earthing_type,
                    xmr_type: formData.xmr_type,
                    xmr_core_type: formData.xmr_core_type,
                    capacity: formData.capacity,
                    has_fencing: formData.has_fencing,
                    //scheme: formData.scheme,
                    // commissioning_date: formData.remarks,
                    point_name: "Transformer",
                    element_id: selectedMarker.id,
                    project_id: selectedMarker.project_id,
                    fileUrl: formData.fileUrl,
                    imgUrl: formData.imgUrl
                },
                "line_props": {
                    position: formData.position,
                    type: formData.type
                },
                "data_type": "ht_location"
            }
        };
        onFormSave();
        onSave(requestBody);
        toast.success("Data Save Successfully!")
        setRightSidebarVisible(false)
        clearForm();
    };

    // Handle save as copy
    const handleSaveAsCopy = (e) => {
        if (!isSaveAsCopyDisabled) {
            localStorage.setItem('transformerData', JSON.stringify(formData));
            handleSubmit(e);
        }
    };

    // Handle load last copy
    const handleLoadLastCopy = () => {
        const savedData = localStorage.getItem('transformerData');
        if (savedData) {
            setFormData(JSON.parse(savedData));
        }
    };

    // Handle Clear
    const handleStartNew = () => {
        clearForm();
    };

    return (
        <div>
            {showLoadAndStartButtons && (
                <div className="mb-3">
                    <button
                        type="button"
                        className="save-button"
                        style={{
                            padding: "8px 30px",
                            backgroundColor: "#427d9d",
                            borderRadius: "8px",
                            color: "white",
                            fontSize: "12px",
                            marginTop: "20px",
                            marginRight: '10px'
                        }}
                        onClick={handleLoadLastCopy}
                    >
                        Load Last Copy
                    </button>
                    <button
                        type="button"
                        className="save-button"
                        style={{
                            padding: "8px 30px",
                            backgroundColor: "#427d9d",
                            borderRadius: "8px",
                            color: "white",
                            fontSize: "12px",
                            marginTop: "20px",
                        }}
                        onClick={handleStartNew}
                    >
                        Clear
                    </button>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="support_type">Support Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="support_type"
                                value={formData.support_type}
                                onChange={handleChange}
                            >
                                <option value="">Select support type</option>
                                <option value="PSC">PSC</option>
                                <option value="MS GIRDER">MS GIRDER</option>
                                <option value="TABULAR">TABULAR</option>
                                <option value="FRP">FRP</option>
                                <option value="RAIL">RAIL</option>
                                <option value="PLINTH">PLINTH</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="structure_type">Structure Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="structure_type"
                                value={formData.structure_type}
                                onChange={handleChange}
                            >
                                <option value="">Select structure type</option>
                                <option value="Double Pole">Double Pole</option>
                                <option value="Single Pole">Single Pole</option>
                                <option value="Double Pole+EPD">Double Pole+EPD</option>
                                <option value="Plinth">Plinth</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="la_type">LA Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="la_type"
                                value={formData.la_type}
                                onChange={handleChange}
                            >
                                <option value="">Select LA type</option>
                                <option value="Fix">Fix</option>
                                <option value="Fuse">Fuse</option>
                                <option value="Not Installed">Not Installed</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="ht_fuse_type">HT Fuse Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="ht_fuse_type"
                                value={formData.ht_fuse_type}
                                onChange={handleChange}
                            >
                                <option value="">Select HT fuse type</option>
                                <option value="Drop Out Fuse">Drop Out Fuse</option>
                                <option value="Rewirable Fuse">Rewirable Fuse</option>
                                <option value="HRC Fuse">HRC Fuse</option>
                                <option value="Not Installed">Not Installed</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="lt_fuse_type">LT Fuse Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="lt_fuse_type"
                                value={formData.lt_fuse_type}
                                onChange={handleChange}
                            >
                                <option value="">Select LT fuse type</option>
                                <option value="Rewirable Fuse">Rewirable Fuse</option>
                                <option value="HRC Fuse">HRC Fuse</option>
                                <option value="Circuit Breaker">Circuit Breaker</option>
                                <option value="Drop Out Fuse">Drop Out Fuse</option>
                                <option value="Not Installed">Not Installed</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="earthing_type">Earthing Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="earthing_type"
                                value={formData.earthing_type}
                                onChange={handleChange}
                            >
                                <option value="">Select earthing type</option>
                                <option value="GI Wire">GI Wire</option>
                                <option value="GI Strip">GI Strip</option>
                                <option value="Not Provided">Not Provided</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="xmr_type">Transformer Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="xmr_type"
                                value={formData.xmr_type}
                                onChange={handleChange}
                            >
                                <option value="">Select transformer type</option>
                                <option value="Dist Trans (Dry)">Dist Trans (Dry)</option>
                                <option value="Dist Trans (Dry+LV Encl)">Dist Trans (Dry+LV Encl)</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="xmr_core_type">Transformer Core Type *</label>
                            <select
                                className="form-select form-select-sm"
                                id="xmr_core_type"
                                value={formData.xmr_core_type}
                                onChange={handleChange}
                            >
                                <option value="">Select transformer core type</option>
                                <option value="CRGO">CRGO</option>
                                <option value="Amorphous Core">Amorphous Core</option>
                                <option value="Not Available">Not Available</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="capacity">Capacity *</label>
                            <select
                                className="form-select form-select-sm"
                                id="capacity"
                                value={formData.capacity}
                                onChange={handleChange}
                            >
                                <option value="">Select capacity</option>
                                <option value="25 KVA">25 KVA</option>
                                <option value="50 KVA">50 KVA</option>
                                <option value="100 KVA">100 KVA</option>
                                <option value="200 KVA">200 KVA</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="has_fencing">Has Fencing *</label>
                            <select
                                className="form-select form-select-sm"
                                id="has_fencing"
                                value={formData.has_fencing}
                                onChange={handleChange}
                            >
                                <option value="">Select if fencing</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="imgUrl">Upload Image:</label>
                            <FileUpload fileName={'imgUrl'} existingFile={formData.imgUrl} className="form-control" onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} /> {/* Use the FileUpload component */}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="fileUrl">Upload Document:</label>
                            <FileUpload fileName={'fileUrl'} existingFile={formData.fileUrl} className="form-control" onFileUpload={handleFileUpload}  onFileRemove={handleFileRemove} /> {/* Use the FileUpload component */}
                        </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="scheme">Scheme *</label>
                            <select
                                className="form-select form-select-sm"
                                id="scheme"
                                value={formData.scheme}
                                onChange={handleChange}
                            >
                                <option value="">Select scheme</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label1" htmlFor="remarks">Remarks</label>
                            <textarea
                                className="form-control"
                                id="remarks"
                                value={formData.remarks}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <h6 className="sub-menu-title mb-0">
                                Line Properties
                            </h6>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <SelectInput 
                            name="position"
                            label="Line Position *"
                            options={["Overhead", "Underground"]}
                            value={formData.position}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12">
                        <SelectInput 
                            name="type"
                            label="Line Type *"
                            options={formData.position === "Underground" ? ["3C X 70sqmm","3C X 95sqmm","3C x 120sqmm","3C X 150sqmm","3C X 185sqmm","3C X 225sqmm","3C X 240sqmm","3C X 300 sqmm"] : ["AAAC-Weasel (34 mm2)", "AAAC-Rabbit (55 mm2)", "AAAC-DOG (100 mm2)", "AAAC Rabbit - Coated (55mm2)", "AB Cable (120 mm2)", "AB Cable (35 mm2)", "AB Cable (70 mm2)", "AB Cable (95 mm2)", "AB Cable(120 mm2)", "ACSR-DOG (105 mm2)", "ACSR-Rabbit (50 mm2)", "ACSR-Rabbit (55 mm2)", "ACSR-Wease (30 mm2)", "ACSR-Weasel (34 mm2)", "AL 59 Dog (117 mm2)", "MVCC AL59 ACS Rabbit(52 mm2)", "3C X 70sqmm", "3C X 95sqmm", "3C x 120sqmm", "3C X 150sqmm", "3C X 185sqmm", "3C X 225sqmm", "3C X 240sqmm", "3C X 300 sqmm"]}
                            value={formData.type}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="text-center">
                    <button
                        type="button"
                        className="save-button"
                        onClick={handleSaveAsCopy}
                        disabled={isSaveAsCopyDisabled}
                    >
                        Save as Copy
                    </button>
                    <button
                        type="submit"
                        className="save-button"
                        onClick={handleSubmit}
                        disabled={isSaveDisabled}
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TransformerAddNetwork;
