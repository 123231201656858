import React, { useState } from 'react';
import './css/login.css';
import { toast } from 'react-toastify';

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError('Please enter both username and password');
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const formdata = new FormData();
    formdata.append("email", username);
    formdata.append("password", password);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/api/login', requestOptions);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.success !== true) {
        throw new Error(result.message || 'Login failed');
      }
      localStorage.setItem('user', username);
      onLogin(result.data);
      toast.success("Login successful.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      setError(error.message);
      toast.error("Incorrect Credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="login-container">
      <h2><b>Fornax Mapview</b></h2><br />
      <h4>Login here</h4>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginPage;
