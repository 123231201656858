// components/privateRoutes/PrivateRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem('_auth'); // Check if the user is authenticated

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
