import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Download, ArrowRight } from "react-bootstrap-icons";

const ModalData = ({
    show,
    fullscreen,
    setShow,
    setSidebarVisible,
    visibleElements,
    selectedProjects,
    setblankMapOpen,
    toggleObjectElements,
    setToggleProjects,
    isMapbookshow
}) => {
    const [selectedSize, setSelectedSize] = useState("a4");
    const [toggleLineLengthLabel, setToggleLineLengthLabel] = useState(false);
    const [toggleXMRCapacity, setToggleXMRCapacity] = useState(false);
    const [toggleElements, setToggelElements] = useState(visibleElements);
    const [toggleStates, setToggleStates] = useState({});

    const handleButtonClick = (size) => {
        setSelectedSize(size);
    };

    useEffect(() => {
        const initialStates = selectedProjects.reduce(
            (acc, item) => ({
                ...acc,
                [item.project_id]: {
                    label: item.label,
                    isToggled: true,
                },
            }),
            {}
        );
        setToggleStates(initialStates);
    }, [selectedProjects]);

    useEffect(() => {
        setToggleProjects(toggleStates)
    }, [toggleStates]);

    const handleToggleChange1 = (projectId) => {
        setToggleStates((prev) => ({
            ...prev,
            [projectId]: {
                ...prev[projectId],
                isToggled: !prev[projectId].isToggled,
            },
        }));
    };
    const handleToggleChange = (key) => {
        setToggelElements((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
        toggleObjectElements(key);
    };

    const buttonStyles = (size) => {
        const sizes = { a0: 60, a1: 55, a2: 50, a3: 45, a4: 40 }; // Button sizes
        const fontSize = {
            a0: "18px",
            a1: "16px",
            a2: "14px",
            a3: "12px",
            a4: "10px",
        }; // Font sizes

        return {
            width: `${sizes[size]}px`,
            height: `${sizes[size]}px`,
            fontSize: fontSize[size],
            backgroundColor: size === selectedSize ? "#007bff" : "white",
            color: size === selectedSize ? "white" : "black",
            border: `1px solid ${
                size === selectedSize ? "#007bff" : "#d7d7d7"
            }`,
            borderRadius: "5px",
            margin: "0 15px 0 0",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
        };
    };

    const handleClose = () => {
        setShow(false);
        setSidebarVisible(true);
    };
    const handleOpenMap = () => {
        setShow(false);
        setblankMapOpen({
            selectedSize,
            toggleLineLengthLabel,
            toggleXMRCapacity,
            isMapbook: false,
        });
        // setSidebarVisible(true);
    };

    const handleOpenMapbook = () => {
        setShow(false);
        setblankMapOpen({
            selectedSize,
            toggleLineLengthLabel,
            toggleXMRCapacity,
            isMapbook: true,
        });
        // setSidebarVisible(true);
    };

    return (
        <>
            <Modal show={show} fullscreen={fullscreen} onHide={handleClose} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Download />{" "}
                        <span className="ms-3 fs-5">
                            {" "}
                            Download Map
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* First Label and Box */}
                    <div className="sub-heading">
                        {/* <ArrowRight className="me-2" /> */}
                        {" "}
                        {/* Right arrow icon */}
                        <label className="sub-heading mb-0">
                            Feeders
                        </label>
                    </div>
                    <div className="modal-item-wrap">
                        <div className="row">
                            {selectedProjects?.map((item, index) => (
                                <div className="col-md-3 col-lg-2">
                                    <div className="custom-switch-item my-2"
                                        key={item.project_id}>
                                        <Form.Check
                                            type="switch"
                                            id={`toggle-${item.project_id}`}
                                            checked={
                                                toggleStates[item.project_id]
                                                    ?.isToggled || false
                                            }
                                            onChange={() =>
                                                handleToggleChange1(item.project_id)
                                            }
                                            className="me-2"
                                        />
                                        <div>
                                            <span className="me-2 fw-medium">
                                                {index + 1}.
                                            </span>
                                            {item?.label || ""} ({item?.project_id})
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Second Label and Buttons in a Box */}
                    {/* <div style={{ marginTop: "30px" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <ArrowRight style={{ marginRight: "10px" }} />
                            <label style={{ fontSize: "16px" }}>
                                Select Layout (Page Size)
                            </label>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "#f9f9f9",
                                flexWrap: "wrap",
                            }}
                        >
                            {["a0", "a1", "a2", "a3", "a4"].map((size) => (
                                <button
                                    key={size}
                                    onClick={() => handleButtonClick(size)}
                                    style={buttonStyles(size)}
                                >
                                    {size.toUpperCase()}
                                </button>
                            ))}
                        </div>
                    </div> */}

                    {/* Third Section */}
                    <div className="mt-4">
                        <div className="sub-heading">
                            {/* <ArrowRight className="me-2" /> */}
                            <label className="sub-heading mb-0">
                                Set below options to fulfill your need
                            </label>
                        </div>
                        <div className="modal-item-wrap">
                            <h5 className="sub-heading fs-6 fw-bolder">HT Network</h5>
                            <div>
                                <div className="row">
                                    {Object.keys(toggleElements).map(
                                        (value, key) => {
                                            return (
                                                <div className="col-md-3 col-xl-2">
                                                <Form.Check
                                                    type="switch"
                                                    id="toggle-allHTNetwork"
                                                    checked={
                                                        toggleElements[value]
                                                    }
                                                    onChange={() =>
                                                        handleToggleChange(
                                                            value
                                                        )
                                                    }
                                                    label={
                                                        value
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        value
                                                            .slice(1)
                                                            .replace(/_/g, " ")
                                                    }
                                                    className="mt-3"
                                                />
                                                </div>
                                            );
                                        }
                                        
                                    )}
                                    
                                    <div className="col-sm-12"><hr/></div>
                                    <div className="col-md-4 col-xl-3">
                                        <Form.Check
                                            type="switch"
                                            id="toggle-XMRCapacity"
                                            checked={toggleXMRCapacity}
                                            onChange={() => setToggleXMRCapacity(prevToggleXMRCapacity => !prevToggleXMRCapacity)}
                                            label={"Is XMR-Capacity Visible?"}
                                            className="me-2"
                                        />
                                    </div>
                                    <div className="col-md-4 col-xl-3">
                                        <Form.Check
                                            type="switch"
                                            id="toggle-LineLengthLabel"
                                            checked={toggleLineLengthLabel}
                                            onChange={() => setToggleLineLengthLabel(prevToggleLineLengthLabel => !prevToggleLineLengthLabel)}
                                            label={"Is Line Length Visible?"}
                                            className="me-2"
                                        />
                                    </div>
                                </div>
                                {/* <div>
                                    <ReactToPrint
                                        content={reactToPrintContent}
                                        documentTitle="AwesomeFileName"
                                        onAfterPrint={handleAfterPrint}
                                        onBeforeGetContent={handleOnBeforeGetContent}
                                        onBeforePrint={handleBeforePrint}
                                        removeAfterPrint
                                        trigger={reactToPrintTrigger}
                                    />
                                    {loading && <p className="indicator">onBeforeGetContent: Loading...</p>}
                                    <ComponentToPrint ref={componentRef} text={text} />
                                    </div> */}
                            </div>
                        </div>
                        <div className="text-end"
                        >
                            {isMapbookshow ?
                            <button
                                onClick={handleOpenMapbook}
                                className="btn-custom px-5 mt-5"
                            >
                                Download map book
                            </button> :
                            <button
                                onClick={handleOpenMap}
                                className="btn-custom px-5 mt-5"
                            >
                                Download map
                            </button>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalData;
